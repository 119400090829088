/*
 * @Author: your name
 * @Date: 2021-08-30 16:15:34
 * @LastEditTime: 2021-09-02 15:25:22
 * @LastEditors: imsixn
 * @Description: In User Settings Edit
 * @FilePath: \angel-color\src\index.js
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/iconfont/iconfont.css'
import App from './App';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);